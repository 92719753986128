$(function() {

  var _Swiper;
  
  // 以下為 Cookies 記錄在當前瀏覽器僅顯示一世
  if(!sessionStorage.getItem("agreeterm")){
    $('.cookies').stop(true).addClass('display');
  }

  $(".close-cookies").on('click',function(e) {
    e.preventDefault();
    sessionStorage.setItem("agreeterm", true);
    $(".cookies").addClass("close-cookies");
  });

  // 以下為Swiper設定
 
   // 共用Auto 
   var auplayFun = {
     delay: 3500,
     //stopOnLastSlide: false,
     disableOnInteraction: false,
   }
 
   // 首頁Banner
   if($('.swiper-bannerIn').length > 0) {
     var swiper_bannerIn = new Swiper(".swiper-bannerIn .swiper-container", {
      // direction: "horizontal",
      loop: true,
      centeredSlides : true,
      slidesPerView:'auto',
      navigation: {
        nextEl: '.swiper-bannerIn .swiper-button-next',
        prevEl: '.swiper-bannerIn .swiper-button-prev',
      },
      grabCursor: true,
      speed: 1000,
      pagination: '.swiper-bannerIn .swiper-pagination',
      paginationClickable: true,
      // parallax: true,
      // effect: "slide",
      reverseDirection: true,

      // autoplay: {
      //   reverseDirection: true,
      // },
      autoplay: false, //auplayFun
      // mousewheelControl: 1,
     });
   }

  // 首頁服務項目
  var indexpageServiceSwiper = new Swiper('.index-pageService-swiper .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 0,
	  speed: 1000,
    navigation: {
      nextEl: '.index-pageService-swiper .swiper-button-next',
      prevEl: '.index-pageService-swiper .swiper-button-prev',
    },

    breakpoints: { 
      320: {  //当屏幕宽度大于等于320
        slidesPerView: 1,
        spaceBetween: 10
      },
      768: {  //当屏幕宽度大于等于768 
        slidesPerView: 3,
        spaceBetween: 0
      },
      1280: {  //当屏幕宽度大于等于1280
        slidesPerView: 3,
        spaceBetween: 0,
      }
    },

    on: {
      init: function () {
        $('.index-pageService-swiper .swiper-slide').addClass('changed');
      },
      slideChangeTransitionStart : function() {
        $('.index-pageService-swiper .swiper-slide').addClass('changing');
        $('.index-pageService-swiper .swiper-slide').removeClass('changed');
      },
      slideChangeTransitionEnd : function() {
        $('.index-pageService-swiper .swiper-slide').removeClass('changing');
        $('.index-pageService-swiper .swiper-slide').addClass('changed');
      }
    },

  });

  
// 首頁歷史沿革 : 電腦 destroy pageHistory
 var indexHistorySwiper = 'destroy';

 $(function(){
     $(window).on('resize', function(){
        SetindexHistorySwiper();
     });
     SetindexHistorySwiper();
 });
 
 function SetindexHistorySwiper(){
     var wh = viewport();
     if(wh.width <= 1100){
         if(indexHistorySwiper == "destroy"){
            indexHistorySwiper = new Swiper('.index-pageHistory.pageHistory .swiper-container', {
              slidesPerView: 3,
              spaceBetween: 0,
              speed: 1000,
              navigation: {
                nextEl: '.index-pageHistory.pageHistory .swiper-button-next',
                prevEl: '.index-pageHistory.pageHistory .swiper-button-prev',
              },
          
              breakpoints: { 
                320: {  //当屏幕宽度大于等于320
                  slidesPerView: 1,
                  spaceBetween: 0
                },
                768: {  //当屏幕宽度大于等于768 
                  slidesPerView: 2,
                  spaceBetween: 0
                }
              },
            });
         }else{
            indexHistorySwiper.update();
         }
     }else {
        $('.index-pageHistory.pageHistory .pageHistory-timeline-list').removeClass('swiper-slide');
        $('.index-pageHistory.pageHistory .swiper-button').hide();
        if(indexHistorySwiper != "destroy"){
          indexHistorySwiper.destroy(false, true);
          indexHistorySwiper = 'destroy';
        }
     }
 }

//  about-pageHistory
var aboutpageHistory = new Swiper('.about-pageHistory .swiper-container', (_Swiper = {
  autoplay:auplayFun,
  speed: 1000,
  slidesPerView: 3,
  spaceBetween: 0
}, _defineProperty(_Swiper, "speed", 1000), _defineProperty(_Swiper, "navigation", {
  nextEl: '.about-pageHistory .swiper-button-next',
  prevEl: '.about-pageHistory .swiper-button-prev'
}), _defineProperty(_Swiper, "breakpoints", {
  320: {
    //当屏幕宽度大于等于320
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: true //高度随内容变化

  },
  768: {
    //当屏幕宽度大于等于768
    slidesPerView: 2,
    spaceBetween: 0,
    autoHeight: false //高度随内容变化

  }
}), _defineProperty(_Swiper, "on", {
  init: function(swiper) {
    let activeIndex = swiper.activeIndex;
    let rangStart = activeIndex * swiper.params.slidesPerView;
    let rangEnd = rangStart + swiper.params.slidesPerView - 1;
    let rangeI = [];
    for(let i=rangStart;i<=rangEnd;i++) {
        rangeI.push(i);
    }
    let delay = 0;
    for(let i=0;i<swiper.slides.length;i++) {
        let currentSlide = $(swiper.slides[i]);
        currentSlide.find('.history_wow.history_animated').removeClass('history_animated');
        if (rangeI.includes(i)) {
            currentSlide.find('.history_wow').each(function(){
              window.setTimeout(() => {
                  $(this).addClass('history_animated');
              }, delay * 1000);
              delay++;
            });
        }
    }
},
slideChangeTransitionEnd: function(swiper) {
    let activeIndex = swiper.activeIndex;
    let rangStart = swiper.activeIndex;
    let rangEnd = rangStart + swiper.params.slidesPerView - 1;
    let rangeI = [];
    for(let i=rangStart;i<=rangEnd;i++) {
        rangeI.push(i);
    }
    let delay = 0;
    for(let i=0;i<swiper.slides.length;i++) {
        let currentSlide = $(swiper.slides[i]);

        if (rangeI.includes(i)) {
            currentSlide.find('.history_wow').each(function(){
              if (!$(this).hasClass("history_animated")) {
                window.setTimeout(() => {
                    $(this).addClass('history_animated');
                }, delay * 1000);
                delay++;
              }
            });
        } else {
          currentSlide.find('.history_wow.history_animated').removeClass('history_animated');
        }
    }
}
  
}), _Swiper));
   
  // 打開搜尋bar
  $('.toggle-search').on('click',function() {
    $('.search-formList').toggleClass('open');
    return false;
  });

  // 
  $('.list-group-mobile-btn').on('click',function(){
    $('.link-horizontal-wrap').toggleClass('open');
    return false;
  })
 
   // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });
 
   $(window).on("load resize scroll", function (e) {
     var scroll = $(this).scrollTop();
     var wdh = $(window).height();
     if (scroll > 50) {
         $(".float-link").addClass("active");
     } else {
         $(".float-link").removeClass("active");
     }
   });

   // BS navbar-collapse show / hidden
   $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })
 
  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });
 
   // 文字點點點
   checkEllipsis('.jsEllipsis',50);

  // Animated.css with Js
  // const aniEl = document.querySelector('.el_animate');
  // aniEl.classList.add('animate__animated', 'animate__fadeInLeft');
  // aniEl.style.setProperty('--animate-duration', '0.5s');
  
  var wow = new WOW({
    boxClass: 'wow',            // 欲套用wow.js的class                      (預設wow)
    animateClass: 'animated',   // 欲修改設定animat.css 的類別名稱            (預設animated)
    offset: 0,                  // 當用戶滾動並到達這個距離時才開始執行動畫    (預設是0, 因此捲動到顯示時才出現動畫) 
    mobile: false,               // 是否在行動裝置上執行動畫                   (預設是true)
    live: true,                  // 非同步產生的內容是否要套用                  (預設是true)
  });
  
  wow.init();

  // swiper with bootstrap
  $(".modal-serSwiper").on('show.bs.modal', function() {
    setTimeout(function() {
      var swiper = new Swiper(".modal-body-swiper .mySwiper", {
        pagination: {
          el: ".modal-body-swiper .swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".modal-body-swiper .swiper-button-next",
          prevEl: ".modal-body-swiper .swiper-button-prev",
        },
      });
    }, 500);
  });

 });
 
 function resize() {
   var winHeight = $(window).height();
   $(".modal-autoheight .modal-body").css({
     width: "auto",
     height: (winHeight - 200) + "px"
   });
 }
 
 // 取得頁面寬度
 function viewport() {
   var e = window,
       a = 'inner';
   if (!('innerWidth' in window)) {
       a = 'client';
       e = document.documentElement || document.body;
   }
   return { width: e[a + 'Width'], height: e[a + 'Height'] };
 }
 
 // 文字點點點
 function checkEllipsis(name,len){
   $(name).each(function(i){
     if($(this).text().length>len){
         $(this).attr("title",$(this).text());
         let text=$(this).text().substring(0,len-1)+"...";
         $(this).text(text);
     }
   });
 }
 